import React, { useState } from 'react';
import EditableTable from '../inputs/EditableTable';
import TextField from '@material-ui/core/TextField';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import BlockIcon from '@material-ui/icons/Block'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import { useAuth } from "../../context/auth";
import { useMessage } from "../../context/message";
import ReportsTable from '../display/ReportsTable';
import AlertDialog from '../inputs/AlertDialog'
import AddUser from './AddUser';
import AddBoxIcon from '@material-ui/icons/AddBox'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import MapIcon from '@material-ui/icons/Map'

export default function UserTable() {
  const { setMessage } = useMessage();
  const { setUsername, setUser_id, setTeam_id, auth } = useAuth();
  const [toggle, fetchData] = useState(false)
  //add user dialog
  const [open, setOpen] = useState(false)
  const [edit_user_id, set_edit_user_id] = useState(0)
  const [deleteDialog, setDelete] = useState(false)

  const reportTable = (rowData) => {
    return (
      <ReportsTable user_id= {rowData.user_id} />
    )
  }

  const columns = [
    {
      title: 'User ID',
      field: 'user_id',
      editable: 'never'
    },
    {
      title: 'First Name',
      field: 'first_name',
    },
    {
      title: 'Last Name',
      field: 'last_name',
    },
    {
      title: 'Company',
      field: 'company',
    },
    {
      title: 'Job Title',
      field: 'job_title'
    },
    {
      title: 'Username',
      field: 'username',
    },
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'Password',
      field: 'password',
      editable: 'onAdd',
      editComponent: props => (
        <TextField
          label='Password'
          type='password'
          value={props.value || ''}
          onChange={e => props.onChange(e.target.value)}
        />
      )
    },
    {
      title: 'Permission Level',
      field: 'category',
      lookup: {
        Viewer: 'Viewer',
        Trader: 'Trader',
        Terminal: 'Terminal',
        Admin: 'Admin',
      }
    },
    {
      title: 'Office Phone',
      field: 'office_phone',
      type: 'numeric',
    },
    {
      title: 'Cell Phone',
      field: 'cell_phone',
      type: 'numeric',
    },
    {
      title: 'Subscribed',
      field: 'data_sub',
      lookup: {
        0: 'N',
        1: 'Y'
      }
    },
    {
      title: 'Overlay Access',
      field: 'overlayAccess',
      lookup: {
        0: 'N',
        1: 'Y'
      }
    },
    {
      title: 'Reports',
      field: 'reports',
      editable: 'never'
    }
  ];

  const deleteUser = () => {
    fetch(`/api/user/${edit_user_id}`, {
      method: 'DELETE',
      headers: {
        'x-access-token': sessionStorage.getItem('token')
      }
    })
    .then((response) => auth(response))
    .then((response) => {
      if (response.message.type === 'success') {
        fetchData(!toggle);
      }
      setMessage(response.message);
    })
    .catch((error) => {
      setMessage({'type': 'error', 'text': 'Error! Unable to delete user.'})
      console.error('Error:', error);
    })
    set_edit_user_id(0)
  }

  return (
    <div>
      <AddUser
        open = {open}
        setOpen = {setOpen}
        user_id = {edit_user_id}
        setUser_id = {set_edit_user_id}
        fetchData = {fetchData}
        toggle = {toggle}
      />
      <AlertDialog
        open = {deleteDialog}
        setOpen = {setDelete}
        title = 'Delete User'
        content = 'Are you sure you want to delete this user?'
        submitText = 'Delete'
        submit = {deleteUser}
      />
      <EditableTable
        title='Users'
        table='user'
        toggle = {toggle}
        fetchData = {fetchData}
        columns={columns}
        ownership='all'
        actions={[
          {
            icon: () => <AddBoxIcon color = 'primary'/>,
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: () => {
              setOpen(true)
            }
          },
          {
            icon: () => <EditIcon color = 'primary'/>,
            tooltip: 'Edit User',
            onClick: (e, rowData) => {
              set_edit_user_id(rowData.user_id)
              setOpen(true)
            }
          },
          {
            icon: () => <DeleteOutlineIcon color = 'primary'/>,
            tooltip: 'Delete User',
            onClick: (e, rowData) => {
              set_edit_user_id(rowData.user_id)
              setDelete(true)
            }
          },
          rowData => ({
            icon: () => <BlockIcon color = 'primary'/>,
            tooltip: 'Suspend user',
            onClick: (event, rowData) => {
              if (window.confirm('Are you sure you want to suspend user?')){
                fetch(`/api/user/suspend/${rowData.user_id}`, {
                  method: 'POST',
                  headers: {
                    'x-access-token': sessionStorage.getItem('token')
                  }
                })
                .then((response) => auth(response))
                .then((response) => {
                  fetchData(!toggle)
                  setMessage(response.message)
                })
                .catch((error) => {
                  console.log('Error:', error)
                })
              }
            }
          }),
          rowData => ({
            icon: () => <VpnKeyIcon color = 'primary'/>,
            tooltip: 'Login as user',
            onClick: (event, rowData) => {
              if (window.confirm(`Are you sure want to login as ${rowData.username}?`)) {
                fetch(`/api/${rowData.user_id}/login`, {
                  method: 'GET',
                  headers: {
                    'x-access-token': sessionStorage.getItem('token')
                  }
                })
                .then((response) => auth(response))
                .then((response) => {
                  sessionStorage.setItem('token', response.token);
                  sessionStorage.setItem('username', response.username);
                  sessionStorage.setItem('user_id', response.user_id);
                  sessionStorage.setItem('team_id', response.team_id);
                  setUsername(response.username);
                  setUser_id(response.user_id);
                  setTeam_id(response.team_id);
                  setMessage(response.message);
                })
                .catch((error) => {
                  console.error('Error:', error);
                })
              }
            }
          }),
          rowData => ({
            icon: () => <EqualizerIcon color = 'primary'/>,
            tooltip: rowData.data_sub === 0 ? 'Subscribe to Data' : 'Unsubscribe to Data',
            onClick: (event, rowData) => {
              if (window.confirm(`Are you sure you want to ${rowData.data_sub === 0 ? 'subscribe' : 'unsubscribe'} this user?`)){
                fetch(`/api/user/data/${rowData.data_sub === 0 ? 'subscribe' : 'unsubscribe'}/${rowData.user_id}`, {
                  method: 'PUT',
                  headers: {
                    'x-access-token': sessionStorage.getItem('token')
                  }
                })
                .then((response) => auth(response))
                .then((response) => {
                  fetchData(!toggle)
                  setMessage(response.message)
                })
                .catch((error) => {
                  console.log('Error:', error)
                })
              }
            }
          }),
          rowData => ({
            icon: () => <MapIcon color = 'primary'/>,
            tooltip: rowData.overlayAccess === 0 ? 'Grant Overlay Access' : 'Remove Overlay Access',
            onClick: (event, rowData) => {
              if (window.confirm(`Are you sure you want to ${rowData.overlayAccess === 0 ? 'grant access': 'revoke access'} to this user?`)){
                fetch(`/api/user/overlayAccess/${rowData.overlayAccess === 0 ? 'grant' : 'revoke'}/${rowData.user_id}`,{
                  method: 'PUT',
                  headers: {
                    'x-access-token': sessionStorage.getItem('token')
                  }
                })
                .then((response) => auth(response))
                .then((response) => {
                  fetchData(!toggle)
                  setMessage(response.message)
                })
                .catch((error) => {
                  console.log('Error:', error)
                })
              }
            }
          })
        ]}
        detailPanel = {reportTable}
      />
    </div>

  );
}
